import $ from 'jquery';

require('jquery-ui');
require('../../js/plugins/multiSelect');

import {
    loadReportParameters,
    getOperatorReferencePeriod,
    updateReferencePeriod,
    buildPipedUrlParametersFromNameValuePairs
} from "../shared/report-parameters";

$(() => {
    app.DOM.form_input.filter('select#report_selector').on('change', (e) => {

        const report = $(e.target).val();
        if (report !== '') {
            loadReportParameters(report, {});
        } else {
            app.DOM.form('div#parameters_region').empty();
            $('button#generate_report').prop('disabled', true);
        }
    });

    app.DOM.form_input.filter('button#generate_report').click(generateReport);
});

function generateReport(e) {
    e.preventDefault();

    const report = $('select#report_selector').val();
    const displayFormat = $('input[name=display]:checked').val();

    const params = $('div#parameters_region input,select,textarea').serializeArray();
    params.push({
        name: 'display',
        value: displayFormat
    });

    const urlParams = buildPipedUrlParametersFromNameValuePairs(params);
    let url = app.CACHE.URL_ADMIN + 'report/generator/generate/' + report + '/' + urlParams;

    if (displayFormat !== 'html') {
        window.setTimeout(iframeLoadingChecker, 100);
        $('div#loading').show();
        $('iframe#report_loader').attr('src', url);
    } else {
        window.open(url);
    }
}

function formatDateYYYYMMDD(date)
{
    return [
        (''+date.getFullYear()).padStart(4, 0),
        (''+(date.getMonth() + 1)).padStart(2, 0),
        (''+date.getDate()).padStart(2, 0)
    ].join('-');
}

function iframeLoadingChecker()
{
    const iframe = document.getElementById('report_loader');
    const iframeInnerDoc = iframe.contentDocument || iframe.contentWindow.document;
    console.log(iframeInnerDoc.readyState);

    if (iframeInnerDoc.readyState == 'complete') {
        hideLoadingPopup();
        return;
    }

    window.setTimeout(iframeLoadingChecker, 100);
}

function hideLoadingPopup()
{
    const $loaded = $('div#loaded');

    $('div#loading').hide();
    $loaded.show();

    window.setTimeout(() => $loaded.hide(), 5000);
}